@keyframes fadeFromBottom {
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
.scrollViewAnimate {
  animation: fadeFromBottom linear;
  animation-timeline: view(100px);
}
